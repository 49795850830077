
import FCImage from '~/components/FCImage.vue';

export default {
  name: 'CardsWithText',
  components: {
    FCImage,
  },
  props: {
    assets: {
      type: Array,
      required: true,
      default: () => [
        {
          src: 'https://a.storyblok.com/f/152150/375x375/44e2537646/fc-website-v2-shop-test-image-004.png',
          alt: 'Placeholder image',
        },
      ],
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    headingStyle: {
      type: String,
      default: 'fc-heading-2',
    },
  },
};
